import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';

// Actions
import { deletePerson, addPerson, editPerson } from 'redux/slices/personSlice';

// Helpers
import { formActions } from 'util/appConstants';
import { defaultTableOptions, getLocalization, mapTableData } from 'util/table-utils';
// Components
import withConfirm from 'components/dialogs/delete';
import ExternPersonsModalForm from './ExternPersonsModalForm';

const TabCurrent = ({ type, contactPersons, initialValues, confirm, onAdd, onRemove }) => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const [opened, setOpened] = useState(false);
  const [record, setRecord] = useState(null);
  const [action, setAction] = useState(null);

  const onClose = () => {
    setOpened(false);
    setRecord(null);
    setAction(null);
  };

  const callbackOnDelete = (e, rowData) => {
    e.stopPropagation();
    confirm(
      () => {
        // if the person is Intern we are just going to remove that person from the contacts
        if (rowData.type !== 'Intern') {
          dispatch(deletePerson(rowData._id));
        }

        return onRemove(rowData);
      },
      {
        description: 'Are you sure?',
      },
    );
  };

  const handleSubmit = async (values) => {
    let res;

    if (action === formActions.add) {
      res = await dispatch(addPerson(values));
    } else {
      res = await dispatch(editPerson(record._id, values));
    }

    onClose();

    return onAdd(res);
  };

  return (
    <>
      {
        opened && (
          <ExternPersonsModalForm
            initialValues={record}
            onSubmit={handleSubmit}
            type={type}
            onClose={onClose}
          />
        )
      }

      <MaterialTable
        data={mapTableData(contactPersons)}
        title=''
        actions={[
          {
            icon: 'add',
            tooltip: 'Add',
            isFreeAction: true,
            onClick: () => {
              setRecord(initialValues);
              setAction(formActions.add);
              setOpened(true);
            },
          },
          (rowData) => ({
            icon: rowData.type === 'Intern' ? '' : 'edit',
            position: 'row',
            disabled: rowData.type === 'Intern',
            onClick: (e, rowData) => {
              e.stopPropagation();
              setRecord({ ...rowData, });
              setAction(formActions.edit);
              setOpened(true);
            },
          }),
          (rowData) => ({
            icon: rowData.type === 'Intern' ? 'delete' : 'delete',
            position: 'row',
            onClick: callbackOnDelete,
          }),
        ]}
        columns={[
          { title: t('Salutation'), field: 'salutation' },
          { title: t('First Name'), field: 'firstName' },
          { title: t('Surname'), field: 'surname' },
          { title: t('Role'), field: 'role', render: (rowData) => rowData.role.map(r => t(r)).join(', ') },
          { title: t('Department'), field: 'department' },
          { title: t('Phone'), field: 'phone' },
          { title: t('E-mail'), field: 'emails' },
        ]}
        options={defaultTableOptions}
        minRows={0}
        localization={getLocalization(t)}
      />
    </>
  );
};

TabCurrent.propTypes = {
  type: PropTypes.oneOf(['CARRIER', 'PARTNER']).isRequired,
  initialValues: PropTypes.shape({}).isRequired,
  confirm: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default withConfirm(TabCurrent);
